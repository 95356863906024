<template>
  <div v-if="isLoading">
    <!-- Loading... -->
    <LoadingOverlay :isLoading="isLoading" />
  </div>
  <div v-else-if="error">
    <!-- {{ error }} -->
    <ErrorView/>
  </div>
  <div v-else>
        <MainImageView :name='place.name' :imageUrl="place.images.photos[0]" :categories='place.categories' :address='address' :about='localizedAboutText'/>
        <SpecialitiesSectionView v-if='place.specialities.isNotEmpty()' :specialities="place.specialities"/>
        <InfoSectionView :distance="distance" :workingHours="place.workingHours"/>
        <PhotosSectionView v-if='place.images.photos.isNotEmpty()' :photos="place.images.photos" />
        <CuisinesSectionView v-if='place.cuisines.isNotEmpty()' :cuisines="place.cuisines"/>
        <InteractiveMenuSectionView v-if='place.interactiveMenu.isNotEmpty()' :interactiveMenu="place.interactiveMenu"/>
        <LastUpdatedSectionView :updatedAt="place.updatedAt"/>
      </div>
 </template>
 
 <script>
 import axios from 'axios'

 import LoadingOverlay from '../components/Shared/LoadingOverlay.vue';
 import MainImageView from '../components/Place/MainImageSection/MainImageSectionView.vue'
 import SpecialitiesSectionView from '../components/Place/SpecialitiesSection/SpecialitiesSectionView.vue'
 import InfoSectionView from '../components/Place/InfoSection/InfoSectionView.vue'
 import PhotosSectionView from '../components/Place/PhotosSection/PhotosSectionView.vue'
 import CuisinesSectionView from '../components/Place/CuisinesSection/CuisinesSectionView.vue'
 import InteractiveMenuSectionView from '../components/Place/InteractiveMenuSection/InteractiveMenuSectionView.vue'
 import LastUpdatedSectionView from '../components/Place/LastUpdatedSection/LastUpdatedSectionView.vue'
 import ErrorView from '@/components/Shared/ErrorView.vue';

import { ref } from 'vue';
import { useHead } from '@vueuse/head';
 
export default {
  name: 'PlacePage',
    components: {
      LoadingOverlay,
      MainImageView,
      SpecialitiesSectionView,
      InfoSectionView,
      PhotosSectionView,
      CuisinesSectionView,
      InteractiveMenuSectionView,
      LastUpdatedSectionView,
      ErrorView
    },
    methods: {
      async fetchPlaceData() {
        try {
          const response = await axios.get(process.env.VUE_APP_API_ENDPOINT_PLACE_BY_ID + this.$route.query.id)
          if (response.data == null || response.data.status == 'draft') {
            this.error = 'Failed to find place by id.'
            this.$router.push({ path: '/not-found' })
          } else {
            this.place = response.data
            updateMetaInfo(this.place, this.localizedAboutText)
          }
        } catch (error) {
          // console.log('error 1:', error.request)
          this.error = 'Failed to load data';
          if (error.response) {
            if (error.response.status == 404) {
              this.$router.push({ path: '/not-found' })
            }
            this.error = `Error: ${error.response.status} - ${error.response.data.message}`;
          } else if (error.request) {
            this.error = 'No response from server';
          } else {
            this.error = 'Request failed to be sent';
          }
        } finally {
          if (this.error == null) {
            this.fetchLocationsData()
          } else {
            this.isLoading = false;
          }       
        }
      },
      async fetchLocationsData() {
        try {
          const response = await axios.get(process.env.VUE_APP_API_ENDPOINT_LOCATIONS)
          if (response.data != null) {
            let currentLocation = response.data.find(item => item.country === this.place.location.country && item.city === this.place.location.city);
            this.location = currentLocation
          }
        } catch (error) {
          // console.log('Error:' + error)
        } finally {
          this.isLoading = false;
        }
      }
  },
  created() {
    if (this.$route.query.id == null) {
      this.$router.push({ path: '/not-found' })
      return
    }

     if (this.$route.query.id.length > 0) {
      this.fetchPlaceData();
     } else {
      this.isLoading = false
      this.error = 'Invalid place id';
     }
   },
   data() {
     return {
       place: Object,
       location: Object,
       isLoading: true,
       error: null
     }
   },
   computed: {
     address() {
       return this.place.location.street + ', ' + this.place.location.city + ', ' + this.place.location.country
     },
     distance() {
       return distanceInMeters(
         this.place.location.coordinate.latitude,
         this.place.location.coordinate.longitude,
         this.location.coordinate.latitude,
         this.location.coordinate.longitude,
       )
     },
     localizedAboutText() {
        return localizedAboutText(this.place)
      },
   },
  setup() {
    // Use useHead to define meta tags based on reactive properties
    useHead({
          title: title,
          meta: [
            { name: 'description', content: description },
            { property: 'og:title', content: title },
            { property: 'og:description', content: description },
            { property: 'og:image', content: image },
          ],
    })
  },
 }

 // Define reactive properties
 const title = ref('Dinao: Where to go in Belgrade')
 const description = ref('Discover the heartbeat of your city with «Dinao», the must-have mobile app for all urban explorers!')
 const image = ref(require('@/assets/dinao-text-logo.png'))

// Function to update the component state
function updateMetaInfo(place, about) {
  title.value = 'Dinao: ' + place.name
  description.value = about
  image.value = place.images.photos[0]
}

function localizedAboutText(place) {
  var about
  switch (getBrowserLanguage()) {
    case 'ru':
      about = place.about.about_ru
      break
    case 'sr':
      about = place.about.about_rs
      break
    default:
      about = place.about.about_en
      break
  }

  if(about !== null && about.isNotEmpty()) {
    return about
  } else if (about.isNotEmpty()) {
    return place.about.about_rs
  } else {
    return null
  }
}
 
function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}
 
function distanceInMeters(lat1, lon1, lat2, lon2) {
   const earthRadiusKm = 6371; // Radius of the Earth in kilometers
   const dLat = degreesToRadians(lat2 - lat1);
   const dLon = degreesToRadians(lon2 - lon1);
 
   lat1 = degreesToRadians(lat1);
   lat2 = degreesToRadians(lat2);
 
   const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
             Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   const distance = earthRadiusKm * c * 1000; // Distance in meters
   return distance;
}

// Localizations
const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0]; // Get the base language code (e.g., "en" from "en-US")
};
 
 </script>
 
 <style>

 </style>
 