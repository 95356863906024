import { useHead } from '@vueuse/head';

export function configureMetaData(title, description, image) {
    useHead({
        title: 'Dinao: ' + title,
        description: description,
        meta: [
            { property: 'og:title', content: title },
            { property: 'og:description', content: description},
            { property: 'og:image', content: image },
        ],
    })
}