<template>
    <div class="overlay">
        <img 
            src = "../../assets/error.svg"
            alt="Error"
        />
        <h1>{{ $t('components.error.text') }}</h1>
        <!-- <ActionButton 
            :title='buttonTitle'
            @click='tapHandler'
        /> -->
    </div>
</template>
  
<script>
// import ActionButton from './ActionButton.vue';

export default {
    components: {
        // ActionButton
    },
    props: {
        // tapHandler: {
        //     type: Function,
        //     required: true
        // }
    },
    computed: {
        buttonTitle() {
            return this.$t('components.error.buttonTitle')
        }
    }
}
</script>
  
<style scoped>
.overlay {
    height: 90vh;
}
h1 {
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 30px;
}
img {
    padding-top: 60px;
    width: 281px;
    height: 256px;
}
</style>