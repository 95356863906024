<template>
    <div class="overlay">
        <img 
            src = "../../assets/notFound.svg"
            alt="Error"
        />
        <h1>{{ $t('components.notFound.title') }}</h1>
        <p>{{ $t('components.notFound.text') }}</p>
        <ActionButton 
            :title='buttonTitle'
            @click='tapHandler'
        />
    </div>
</template>
  
<script>
import ActionButton from '../Shared/ActionButton.vue';

export default {
    components: {
        ActionButton
    },
    props: {
        tapHandler: {
            type: Function,
            required: true
        }
    },
    computed: {
        buttonTitle() {
            return this.$t('components.notFound.buttonTitle')
        }
    }
}
</script>
  
<style scoped>
.overlay {
    height: 90vh;
}
h1 {
    color: black;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 6px;
}
p {
    font-size: 15px;
    padding-bottom: 30px;
}
img {
    padding-top: 60px;
    width: 281px;
    height: 256px;
}
</style>