<template>
    <header>
        <img
            src = "@/assets/logo.svg"
            alt="Dinao"
            @click="showHome"
        />
    </header>
</template>

<script>
import { logAnalyticsEvent } from '@/utils/firebase';

export default {
    name: 'MainHeader',
    methods: {
        showHome() {
            logAnalyticsEvent('header_logo_click');
            this.$router.push({ path: '/' })
        }
    }
}
</script>

<style scoped>
header {
    background-color: #FFAE2E;
}
img {
    cursor: pointer;
    padding: 4px;
}
</style>